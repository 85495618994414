import axios from 'axios'
export default async function CreateUser(userChatId, bitrixID){
    try {
        // console.log('userChatId', userChatId)
        // console.log('bitrixID', bitrixID)
        
        //проверяем если пользователь существует в cms
        const response = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/users-trackings?filters[bitrix_id][$eq]=${bitrixID}`, {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            }
        })
        const responseData = response.data;

        //если пользовательне не существует - создаем
        if(responseData.data && responseData.data.length > 0) {
            return 200
        } else {
            const getUserData = await axios.get(process.env.REACT_APP_BITRIX_API_URL_GETSKYPE + `/user.get?filter[UF_SKYPE]=${userChatId}&filter[ACTIVE]=Y`)
            try {
                const response = await axios.post(process.env.REACT_APP_STRAPI_API_URL + `/users-trackings`, {
                    "data": {
                        "bitrix_id": bitrixID,
                        "telegram_chat_id": userChatId,
                        "Name": getUserData.data.result[0].NAME + ' ' +  getUserData.data.result[0].LAST_NAME
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ` + process.env.REACT_APP_API_TOKEN,
                        'Content-Type': 'application/json'
                    }
                })
                // Сохраняем userTrackingID в localStorage
                // if (response.data && response.data.id) {
                //     await localStorage.setItem('userTrackingID', response.data.id);
                // }
                await localStorage.setItem('userTrackingID', response.data.data.id);
                window.location.reload();
            } catch (error) {
                // console.log(error)
            }
        }
    } catch (error) {
        // console.error('ошибка', error)
    }
}