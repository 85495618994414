import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const DeviceCheck = ({ children }) => {
  const [isMobile, setIsMobile] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkDevice = () => {
      const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      setIsMobile(isMobileDevice);
      setIsLoading(false);
    };
    
    checkDevice();
  }, []);

  if (isLoading) {
    return null; // или спиннер, если нужно
  }

  return isMobile ? children : <Navigate to="/change-device" replace />;
};

export default DeviceCheck;
