import axios from "axios"
export default async function CheckStatus(tg_chat_id){
    console.log('check status: ', tg_chat_id)
    try {
        const getUserData = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/users-trackings?filters[telegram_chat_id][$eq]=${tg_chat_id}`, {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            }
        })
        await localStorage.setItem('userTrackingID', getUserData.data.data[0].id)
        return getUserData.data.data[0].attributes.active
    } catch (error) {
        console.log(error)   
    }

}