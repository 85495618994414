import React, { useEffect, useState } from 'react';

const lines = [
    '> access main program',
    'access: PERMISSION DENIED.',
    '> access main security',
    'access: PERMISSION DENIED.',
    '> access security',
    'access: PERMISSION DENIED.',
    '> access main security grid',
    'access: PERMISSION DENIED'  ];

export default function AccessDenied(){
    const [currentLine, setCurrentLine] = useState(0);
  const [currentChar, setCurrentChar] = useState(0);
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (currentLine < lines.length) {
      if (currentChar < lines[currentLine].length) {
        const timer = setTimeout(() => {
          setDisplayedText(prev => prev + lines[currentLine][currentChar]);
          setCurrentChar(currentChar + 1);
        }, 100); // Установите задержку между символами (100 мс)
        return () => clearTimeout(timer);
      } else {
        const lineTimer = setTimeout(() => {
          setDisplayedText(prev => prev + '\n');
          setCurrentLine(currentLine + 1);
          setCurrentChar(0);
        }, 200); // Установите задержку между строками (500 мс)
        return () => clearTimeout(lineTimer);
      }
    }
  }, [currentChar, currentLine]);

  return (
    <div className="access-denied-container">
      <pre>{displayedText}</pre>
    </div>
  );
}