import axios from "axios"
export default async function ClockInOutRecord(tg_chat_id){
    try {
        const response = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/clock-in-outs?filters[telegram_chat_id][$eq]=${tg_chat_id}&sort=id:desc`, {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            }
        })
        return response.data.data[0]
    } catch (error) {
        
    }
}