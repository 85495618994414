import {Col, Row} from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom'


export default function NavbarBottom() {

    const NavItem = ({to, icon, label}) => {
        const location = useLocation()
        const isActive = location.pathname === to
        
        return (
            <li className={isActive ? 'active': ''}>
                <Link to={to}>
                    {icon}
                    <span>{label}</span>
                </Link>
            </li>
        )
    }

    return(
        <>
            <Row className='NavbarBottom'>
                <Col span={24} className="nav-bottom">
                    <ul>
                    <NavItem to="/" icon={<HomeOutlined className='custom-icon-home' />} label="Home" />
                    <NavItem to="/clock-in" icon={<i className="fa-regular fa-clock"></i>} label="Time Clock" />
                    <NavItem to="/timesheet" icon={<i className="fa-regular fa-file-lines"></i>} label="Timesheets" />
                    <NavItem to="/approvals" icon={<i className="fa-solid fa-person-circle-check"></i>} label="Approvals" />
                    <NavItem to="/menu" icon={<i className="fa-solid fa-bars"></i>} label="Menu" />
                    </ul>
                </Col>
            </Row>
        </>
    )
}

